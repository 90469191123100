body {
  margin: 0;
  padding: 0;
  background-color: white !important;
}

body * {
  box-sizing: border-box;
}

#root {
  height: inherit;
  max-height: 100vh;
}